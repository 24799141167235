import React, { useCallback } from "react";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import Card from "../../../atoms/card/Card";
import ResultsText from "../results-text/ResultsText";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Icon from "../../../atoms/icon/Icon";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import "./IntroMissionObjectives.scss";
import { useResultsContext } from "../context/context";
import classNames from "classnames";
import EditableReportingHeading from "../components/EditableReportingHeading";
import ReportingSubHeading from "../components/ReportingSubHeading";

export const missionObjectivesMaxClicks = 1;

const IntroMissionObjectives = () => {
  const context = useResultsContext();

  const onNext = useCallback(() => {
    if (context.clicks < missionObjectivesMaxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context]);

  const ShowItems = context.noAnimations || context.clicks >= 1;

  return (
    <RoundResultsContainer onNext={onNext}>
      <Card wide fullHeight sharp>
        <VerticalGroup
          className="mission-objectives-slide"
          verticalCenter
          wide
          center
          full
        >
          <EditableReportingHeading defaultHeading=" What Do I Need To Do?" />
          <ReportingSubHeading
            subHeading="Create the best possible virtual bank by caring for customers and
            delivering the highest return to shareholders."
          />

          <InlineGroup block center fullHeight verticalCenter>
            <VerticalGroup
              className="width-50-percent mission-list-container"
              center
              spread
              full
            >
              <InlineGroup
                verticalCenter
                spaceBetweenElements={2}
                block
                className={classNames("mission-list-item", { show: ShowItems })}
              >
                <InlineGroup className="mission-list-icon-container">
                  <Icon fullSize type={"thumbsUp"} />
                </InlineGroup>
                <ResultsText bold size="lg" className="mission-list">
                  Keep Customers and Community Satisfied
                </ResultsText>
              </InlineGroup>

              <InlineGroup
                verticalCenter
                spaceBetweenElements={2}
                block
                className={classNames("mission-list-item", { show: ShowItems })}
              >
                <InlineGroup className="mission-list-icon-container">
                  <Icon fullSize type={"cogs"} />
                </InlineGroup>
                <ResultsText className="mission-list" bold size="lg">
                  Execute Consistently
                </ResultsText>
              </InlineGroup>

              <InlineGroup
                verticalCenter
                spaceBetweenElements={2}
                block
                className={classNames("mission-list-item", { show: ShowItems })}
              >
                <InlineGroup className="mission-list-icon-container">
                  <Icon fullSize type={"graph"} />
                </InlineGroup>
                <ResultsText bold size="lg" className="mission-list">
                  Sustainable Shareholder Returns
                </ResultsText>
              </InlineGroup>
            </VerticalGroup>

            <InlineGroup
              className={classNames("rocket-lady-container", {
                show: ShowItems,
              })}
            >
              <Icon fullSize type={"rocketLady"} />
            </InlineGroup>

            <InlineGroup
              className={classNames("number-one-container", {
                show: ShowItems,
              })}
            >
              <VerticalGroup full wide spaceBetweenElements={8} center>
                <InlineGroup className="first-place-icon-container">
                  <Icon fullSize type={"firstPlace"} />
                </InlineGroup>
                <VerticalGroup className="first-place-list" full>
                  <ResultsText>Sustainable profits.</ResultsText>
                  <ResultsText>Strong dividends.</ResultsText>
                  <ResultsText>Maximise share price.</ResultsText>
                </VerticalGroup>
              </VerticalGroup>
            </InlineGroup>
          </InlineGroup>
        </VerticalGroup>
      </Card>
    </RoundResultsContainer>
  );
};

export default IntroMissionObjectives;
