import React, { useCallback } from "react";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import ResultsText from "../results-text/ResultsText";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Icon from "../../../atoms/icon/Icon";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Card from "../../../atoms/card/Card";
import "./IntroPurposeSlide.scss";
import { useResultsContext } from "../context/context";
import classNames from "classnames";
import EditableReportingHeading from "../components/EditableReportingHeading";
import ReportingSubHeading from "../components/ReportingSubHeading";

export const purposeSlideMaxClicks = 4;

interface Props {}

const IntroPurposeSlide: React.FC<Props> = () => {
  const context = useResultsContext();

  const onNext = useCallback(() => {
    if (context.clicks < purposeSlideMaxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context]);

  const isVisible = (sectionNumber: number) => {
    return context.noAnimations || context.clicks >= sectionNumber;
  };

  return (
    <RoundResultsContainer onNext={onNext}>
      <Card wide fullHeight sharp>
        <VerticalGroup wide center full>
          <EditableReportingHeading defaultHeading=" Purpose" />
          <InlineGroup block center>
            <ReportingSubHeading
              subHeading=" Banks are large and complex organisations. End to end
              understanding is held by a few and decision making takes time."
            />
          </InlineGroup>
          <VerticalGroup
            spaceBetweenElements={8}
            verticalCenter
            className="purpose-slide"
            center
            full
          >
            <VerticalGroup full verticalCenter>
              <div className="purpose-grid-container">
                <div className="purpose-first-column">
                  <VerticalGroup full verticalCenter>
                    <VerticalGroup full wide>
                      <InlineGroup
                        spaceBetweenElements={4}
                        block
                        verticalTop
                        right
                      >
                        <VerticalGroup spaceBetweenElements={4}>
                          <ResultsText bold right>
                            Increase banking business acumen
                          </ResultsText>
                          <ResultsText right>
                            Understanding the rippling impact of decisions
                          </ResultsText>
                        </VerticalGroup>
                        <InlineGroup>
                          <Icon
                            dynamicSize={{
                              min: "40px",
                              preferred: "6vw",
                              max: "900px",
                            }}
                            noMargin
                            type="outcomeOne"
                          />
                        </InlineGroup>
                      </InlineGroup>
                    </VerticalGroup>

                    <VerticalGroup
                      full
                      wide
                      className={classNames("purpose-bottom-outcome", {
                        show: isVisible(3),
                      })}
                    >
                      <InlineGroup
                        spaceBetweenElements={4}
                        block
                        right
                        style={{ maxHeight: "50%" }}
                      >
                        <VerticalGroup wide spaceBetweenElements={4}>
                          <ResultsText bold right>
                            Manage banking trade offs
                          </ResultsText>
                          <ResultsText right>
                            Manage the complex trade-offs between various
                            stakeholder outcomes
                          </ResultsText>
                        </VerticalGroup>
                        <InlineGroup>
                          <Icon
                            dynamicSize={{
                              min: "40px",
                              preferred: "6vw",
                              max: "900px",
                            }}
                            noMargin
                            type="outcomeThree"
                          />
                        </InlineGroup>
                      </InlineGroup>
                    </VerticalGroup>
                  </VerticalGroup>
                </div>

                <InlineGroup
                  verticalCenter
                  center
                  className="purpose-second-column"
                >
                  <Icon
                    dynamicSize={{
                      min: "150px",
                      preferred: "19vw",
                      max: "900px",
                    }}
                    type="outcomeRocket"
                    noMargin
                  />
                </InlineGroup>

                <div className="purpose-third-column">
                  <VerticalGroup full>
                    <VerticalGroup
                      full
                      className={classNames("purpose-top-outcome", {
                        show: isVisible(1),
                      })}
                    >
                      <InlineGroup spaceBetweenElements={4} block>
                        <InlineGroup>
                          <Icon
                            dynamicSize={{
                              min: "40px",
                              preferred: "6vw",
                              max: "900px",
                            }}
                            noMargin
                            type="outcomeTwo"
                          />
                        </InlineGroup>
                        <VerticalGroup spaceBetweenElements={4}>
                          <ResultsText bold>
                            Think long-term and holistically
                          </ResultsText>
                          <ResultsText>
                            Make the links between products areas, and front and
                            vital back offices functions
                          </ResultsText>
                        </VerticalGroup>
                      </InlineGroup>
                    </VerticalGroup>

                    <VerticalGroup
                      full
                      className={classNames("purpose-bottom-outcome", {
                        show: isVisible(2),
                      })}
                    >
                      <InlineGroup
                        spaceBetweenElements={4}
                        block
                        right
                        style={{ maxHeight: "50%" }}
                      >
                        <InlineGroup>
                          <Icon
                            dynamicSize={{
                              min: "40px",
                              preferred: "6vw",
                              max: "900px",
                            }}
                            noMargin
                            type="outcomeFour"
                          />
                        </InlineGroup>
                        <VerticalGroup spaceBetweenElements={4}>
                          <ResultsText bold>
                            Collaborate & challenge in teams
                          </ResultsText>
                          <ResultsText>
                            Learn in a shared learning environment, while having
                            lots of fun
                          </ResultsText>
                        </VerticalGroup>
                      </InlineGroup>
                    </VerticalGroup>
                  </VerticalGroup>
                </div>
              </div>
            </VerticalGroup>
            <InlineGroup
              verticalCenter
              className={classNames("purpose-bottom-info", {
                show: isVisible(4),
              })}
              bgColour="primaryDark"
              center
            >
              <ResultsText className="width-70-percent" colour="white" center>
                Finance Teams are at the intersection of many areas You can
                drive more informed, better thought through decisions. Drives
                our stakeholder outcomes.
              </ResultsText>
            </InlineGroup>
          </VerticalGroup>
        </VerticalGroup>
      </Card>
    </RoundResultsContainer>
  );
};

export default IntroPurposeSlide;
