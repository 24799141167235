import React from "react";
import Card from "../../../atoms/card/Card";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import EditableReportingHeading from "../components/EditableReportingHeading";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import DynamicResultsText from "../results-text/DynamicResultsText";

interface Props {
  projectsEnabled: boolean;
  capitalManagement: API.CapitalManagement;
  round1Config: ModelAPI.Reporting.ReportingRound;
}

const IntroDecisionTime = ({
  projectsEnabled,
  capitalManagement,
  round1Config,
}: Props) => {
  const { durationInMinutes, dealRoomEnabled, excoEnabled } = round1Config;
  return (
    <RoundResultsContainer>
      <Card wide fullHeight>
        <VerticalGroup wide center full spread>
          <EditableReportingHeading defaultHeading="Round 1 Decision Time" />
          <DynamicResultsText>
            Duration in minutes {durationInMinutes}
          </DynamicResultsText>
          <DynamicResultsText>
            You shoudl show {durationInMinutes - 20}
          </DynamicResultsText>
          <DynamicResultsText>
            Deal room enabled {String(dealRoomEnabled)}
          </DynamicResultsText>
          <DynamicResultsText>
            Exco enabled {String(excoEnabled)}
          </DynamicResultsText>
          <DynamicResultsText>
            Projects enabled {String(projectsEnabled)}
          </DynamicResultsText>
          <DynamicResultsText>
            Capital Management {capitalManagement}
          </DynamicResultsText>
        </VerticalGroup>
      </Card>
    </RoundResultsContainer>
  );
};

export default IntroDecisionTime;
